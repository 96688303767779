
import { getDatabase, ref, set, onValue, push, remove, off } from "firebase/database";
import { app } from './base'


const database = getDatabase(app);

// Basic write operations
export async function writeUserData(title, message,radioVal,imageUrl,webLink1,phoneLink1,phoneLink2,message2,message3) {
  const postListRef = ref(database, 'messages/'+ radioVal);
  const newPostRef = push(postListRef);
  if (!imageUrl) {
    throw new Error("Image URL is undefined");
  }
  set(newPostRef, {
    title: title,
    message: message,
    url:imageUrl,
    tel:phoneLink1,
    link:webLink1,
    phone:phoneLink2,
    message2:message2,
    message3:message3
  })
  .then(() => {
    console.log("succes");
  })
  .catch((error) => {
    console.log(error);
    throw new Error(error.message)
  });
}

// Read data -Listen for value events
export function readDataListen(fun,url) {
  let dataArrey = []
  const starCountRef = ref(database, `messages/` + url);

  const listener = onValue(starCountRef, (snapshot) => {
  const data = snapshot.val() ? snapshot.val() : [] ;
  dataArrey = Object.entries(data)
    fun(dataArrey)
  });

  return () => {
    off(starCountRef, "value", listener); // Remove the listener when unmounted
  };
}


export function deleteData(path,keyId) {
  const starCountRef = ref(database, `messages/` + path + "/" + keyId);
  remove(starCountRef)
}




