
import React, { useState } from 'react'
import '../styles/Header.css'
import {
    Link
} from "react-router-dom";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';


import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import HomeIcon from '@mui/icons-material/Home';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SchoolIcon from '@mui/icons-material/School';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import InfoIcon from '@mui/icons-material/Info';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import MyLocationIcon from '@mui/icons-material/MyLocation';
// import PhoneIcon from '@mui/icons-material/Phone';
// import Divider from '@mui/material/Divider';

import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const { SubMenu } = Menu;

const menuCourses = (
    <Menu>
        <Menu.Item key='1'>
            <Link to="/courses/supervisor" className="ant-dropdown-link"  >
                מפקחים
            </Link>
        </Menu.Item>
        <Menu.Item key='2'>
            <Link to="/courses/instructor" className="ant-dropdown-link"  >
                מדריכים
            </Link>
        </Menu.Item>
        <Menu.Item key='3'>
            <Link to="/courses/ipsc" className="ant-dropdown-link"  >
                ירי מעשי
            </Link>
        </Menu.Item>
        <Menu.Item key='4'>
            <Link to="/courses/shootingExperience" className="ant-dropdown-link"  >
                ירי חוויתי
            </Link>
        </Menu.Item>
    </Menu>
);
// let handleClick = (e) => {
//     console.log('click ', e);
// };
export default function Header() {

    let correntColor = 'Secondary'
    const [toggleDrawer, settoggleDrawer] = useState(false)


    return (
        <AppBar position="sticky" className='Header' sx={{ backgroundColor: ' rgba(216, 216, 216,0.7)', boxShadow: 'none' }}>
            <Toolbar className='toolBar'>
                <div className="menuIcon">

                    <IconButton
                        // size="large"
                        edge="start"
                        color={correntColor}
                        aria-label="menu"
                        sx={{ mr: 0 }}
                        onClick={() => { settoggleDrawer(true) }}

                    >
                        <MenuIcon sx={{ fontSize: 30, color: "#005EB8" }} />
                    </IconButton>
                </div>

                <Drawer
                    anchor='right'
                    open={toggleDrawer}
                    onClose={() => settoggleDrawer(false)}

                >
                    <Box color='#005EB8'>
                        <Menu
                            // onClick={handleClick}
                            style={{ width: 200 }}
                            // defaultSelectedKeys={['burgerHome']}
                            // defaultOpenKeys={['sub1']}
                            mode="inline"
                        >
                            {/* this is the menu belonge to the burger */}

                            <Menu.Item key="burgerHome" icon={<HomeIcon fontSize="large" />} >
                                <Link onClick={() => { settoggleDrawer(false) }} to="/" className="ant-dropdown-link" >
                                    דף הבית
                                </Link>

                            </Menu.Item>
                            <Menu.Item key="burgerHomeShop" icon={<ShoppingCartIcon />} >
                                <Link onClick={() => { settoggleDrawer(false) }} to="/shop" className="ant-dropdown-link" >
                                    חנות
                                </Link>

                            </Menu.Item>
                            <SubMenu key="burgerSub2" icon={<SchoolIcon />} title="קורסים">
                                <Menu.Item key='burgerSub2ourse1'>
                                    <Link onClick={() => { settoggleDrawer(false) }} to="/courses/supervisor" className="ant-dropdown-link"  >
                                        מפקחים
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key='burgerSub2ourse2'>
                                    <Link onClick={() => { settoggleDrawer(false) }} to="/courses/instructor" className="ant-dropdown-link"  >
                                        מדריכים
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key='burgerSub2ourse3'>
                                    <Link onClick={() => { settoggleDrawer(false) }} to="/courses/ipsc" className="ant-dropdown-link"  >
                                        ירי מעשי
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key='burgerSub2ourse4'>
                                    <Link onClick={() => { settoggleDrawer(false) }} to="/courses/shootingExperience" className="ant-dropdown-link"  >
                                        ירי חוויתי
                                    </Link>
                                </Menu.Item>
                            </SubMenu>
                            <Menu.Item key="burgerSubAlonClub" icon={<SportsScoreIcon />}>
                                <Link onClick={() => { settoggleDrawer(false) }} to="/alonClub" className="ant-dropdown-link" >
                                    מועדון אלון
                                </Link>
                            </Menu.Item>


                            {/* <Divider /> */}

                            <Menu.Item key="rangeBurger" icon={<MyLocationIcon />}>
                                <Link onClick={() => { settoggleDrawer(false) }} to="/range" className="ant-dropdown-link" >
                                    שאלות נפוצות
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="downloadFiles" icon={<ArrowCircleDownIcon />}>
                                <Link onClick={() => { settoggleDrawer(false) }} to="/download" style={{ color: "#000" }}  >
                                    טפסים להורדה
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="aboutRange" icon={<InfoIcon />}>
                                <Link onClick={() => { settoggleDrawer(false) }} to="/about" style={{ color: "#000" }}  >
                                    אודות
                                </Link>
                            </Menu.Item>

                        </Menu>



                        {/* <a className='buttonTel2' href="tel:09-7665533"><PhoneIcon fontSize="small" />&nbsp; 09-7665533</a> */}

                    </Box>
                </Drawer>

                <div className='navLinks'>
                    <Link className="ant-dropdown-link" to="/">
                        דף הבית
                    </Link>

                    <Link to="/shop" className="ant-dropdown-link"  >
                        חנות נשק ואביזרים
                    </Link>
                    {/* </Dropdown> */}
                    <Dropdown menu={menuCourses} placement='bottom'>
                        <Link to="/courses" className="ant-dropdown-link"  >
                            קורסים  <DownOutlined />
                        </Link>

                    </Dropdown>

                    <Link className="ant-dropdown-link" to="/alonClub">
                        מועדון אלון
                    </Link>

                </div>
                <Link style={{ marginLeft: "5px" }} to="/"><img style={{marginTop:"3px"}} src="/img/logoFinal2.webp" alt="hollander logo" width="68.25" height="65"></img></Link>
            </Toolbar>

        </AppBar >


    );
}